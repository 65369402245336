
import { IProject, multiApiOperations, projects } from "@/api";
import Vue from "vue";
import ProjectCard from "@/components/projects/ProjectCard.vue";
import NewProjectDialog from "@/components/projects/NewProjectDialog.vue";
import NewProjectCard from "@/components/projects/NewProjectCard.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import DisplaySwitcher from "@/components/common/DisplaySwitcher.vue";
import ProjectsList from "@/components/projects/ProjectsList.vue";
import UploadProjectDialog from "@/components/projects/UploadProjectDialog.vue";
import { Actions } from "@/store/models";

export default Vue.extend({
  name: "Projects",
  components: {
    ProjectCard,
    NewProjectDialog,
    NewProjectCard,
    ConfirmationDialog,
    DisplaySwitcher,
    ProjectsList,
    UploadProjectDialog,
  },
  data() {
    return {
      searchQuery: "",
      locked: false,
      newItemDialog: false,
      deleteProjectDialog: false,
      uploadProjectDialog: false,
      isGrid: true,
      localProjects: [] as (IProject & { selected?: boolean })[],
      disableDeleteBtn: true,
    };
  },
  computed: {
    projects: function (): IProject[] {
      return this.$store.state.company.projects || [];
    },
    filteredProjects: function (): IProject[] {
      const result = this.projects.filter((project: IProject) => {
        return project.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
      if (this.locked) {
        return result.filter((p) => {
          return p.status === 0; // 0 is locked, 1 is unlocked
        });
      }
      return result;
    },
  },
  methods: {
    toggleNewItemDialog(dialog: boolean) {
      this.newItemDialog = dialog;
    },
    toggleDeleteProjectDialog(dialog: boolean) {
      this.deleteProjectDialog = dialog;
    },
    selectProject(id: number) {
      const p = this.localProjects.find((p) => p.id === id);
      p!.selected = p!.selected ? false : true;
      this.toggleDeleteBtn();
    },
    selectProjectsFromListView(ids: number[]) {
      this.localProjects.forEach((p) => {
        p.selected = ids.includes(p.id) ? true : false;
      });
      this.toggleDeleteBtn();
    },
    toggleDeleteBtn() {
      this.disableDeleteBtn =
        this.localProjects.filter((p) => p.selected).length < 1;
    },
    async deleteProjects() {
      const selectedProjects = this.localProjects.filter((p) => p.selected);
      const ids: number[] = selectedProjects.map((p) => p.id);
      try {
        await multiApiOperations(projects.delete, ids);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
      this.getProjects();
    },
    getProjects() {
      this.$store.dispatch(Actions.GetCompanyProjects);
    },
  },
  watch: {
    filteredProjects: {
      handler: function () {
        this.localProjects = this.filteredProjects;
      },
      deep: true,
      immediate: true,
    },
  },
});
