
import { IProject } from "@/api";
import Vue from "vue";
import { getDatetime, sizeInMB } from "@/utils";

export default Vue.extend({
  name: "ProjectCard",
  props: {
    project: Object as () => IProject,
  },
  data() {
    return {
      isSelected: false
    };
  },
  computed: {
    size: function (): string {
      return sizeInMB(this.project.size);
    },
    datetime: function (): string {
      return getDatetime(
        this.project.updated_datetime || this.project.created_datetime
      );
    },
  },
  methods: {
    goToProject() {
      this.$router.push(`/projects/${this.project.id}`);
    },
  },
});
