
import { IGroup, IProjectAddRequest, projects } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";
import AssignGroups from "../common/AssignGroups.vue";

export default Vue.extend({
  components: { AssignGroups },
  name: "NewProjectDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      item: {
        name: "",
        company: {
          id: 0,
        },
        associates_groups: [],
      } as IProjectAddRequest,
    };
  },
  computed: {
    companyId: function (): number {
      return this.$store.state.company.id;
    },
    groups(): IGroup[] {
      return this.$store.state.company.groups;
    },
  },
  methods: {
    async submit() {
      if (!this.item.name) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in required details."
        );
        return;
      }
      try {
        this.item.company.id = this.companyId;
        const response = (await projects.add(this.item)).data;
        await this.$store.dispatch(Actions.GetCompanyProjects);
        this.$router.push(`projects/${response.id}`);
        this.$emit("toggleDialog", false);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
