
import Vue from "vue";
import { getDate, sizeInMB, sizeInMBNumber } from "@/utils";
import { IProject } from "@/api";

export default Vue.extend({
  name: "ProjectsList",
  components: {},
  props: {
    projects: Array,
    isGrid: Boolean,
  },
  data() {
    return {
      index: 1,
      selected: [],
      headers: [
        { text: "PROJECT NAME", value: "name" },
        { text: "LAST UPDATED", value: "dateEdited" },
        { text: "USERNAME", value: "username" },
        { text: "PROJECT SIZE", value: "size" },
        { text: "GROUP NAME", value: "group" },
        { text: "STATUS", value: "status" },
        { text: "", value: "action" },
      ],
    };
  },
  computed: {
    selectedIds: function (): number[] {
      return this.selected.map((p: { id: number }) => p.id);
    },
    items: function (): any[] {
      let temp = [] as any;
      this.projects?.forEach((p: any) => {
        temp.push({
          name: p.name,
          dateEdited: getDate(p.updated_datetime || p.created_datetime),
          username: p.updated_by || p.created_by,
          size: sizeInMBNumber(p.size),
          group: p.projects_associate_groups
            .map((g: any) => g.associate_group.name)
            .join(", "),
          status: p.status === 0 ? "Locked" : "Unlocked",
          id: p.id,
          updated_datetime: p.updated_datetime || p.created_datetime,
        });
      });
      return temp;
    },
  },
  methods: {
    customSort(items: any[], sortBy: string[], sortDesc: boolean[]) {
      const index = sortBy[0];
      const isDesc = sortDesc[0];

      items.sort((a, b) => {
        if (index === "dateEdited") {
          const aTimestamp = Date.parse(a.updated_datetime);
          const bTimestamp = Date.parse(b.updated_datetime);
          if (!isDesc) {
            return aTimestamp - bTimestamp;
          } else {
            return bTimestamp - aTimestamp;
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    view(id: number) {
      this.$router.push("projects/" + id);
    },
  },
  watch: {
    selected: {
      handler: function () {
        this.$emit("selectProjects", this.selectedIds);
      },
    },
  },
});
