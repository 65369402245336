
import Vue from "vue";

export default Vue.extend({
  name: "DisplaySwitcher",
  props: {
    isGrid: Boolean,
    displayDivider: Boolean
  },
  data() {
    return {};
  },

  methods: {
    update(val: boolean) {
      this.$emit('update', val)
      this.$emit('update:isGrid', val)
    }
  },
});
