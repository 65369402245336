
import { IGroup } from "@/api";
import Vue from "vue";

export default Vue.extend({
  name: "AssignGroups",
  props: {
    selected: Array,
    disabled: Boolean,
  },
  data() {
    return {
      updated: this.selected,
    };
  },
  computed: {
    groups(): IGroup[] {
      return this.$store.state.company.groups?.map((g: IGroup) => ({
        ...g,
        props: { color: "blue" },
      }));
    },
  },
  methods: {
    onChange() {
      this.$emit("update:selected", this.updated);
      this.$emit("update", this.updated);
    },
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        this.updated = this.selected;
      },
    },
  },
});
